import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
	Button,
	Col,
	Form,
	Input,
	Modal,
	Row,
	Select,
	Space,
	Spin,
	Table,
} from 'antd';
import { CloseCircleOutlined, ExclamationOutlined } from '@ant-design/icons';
import { AppContext, AppContextInterface } from '../../AppProvider';
import { setAccount, setWallet } from '../../store/account/actions';
import { useDispatch, useSelector } from 'react-redux';
import { FORMAT_DATE } from '../../constants';
import { useWindowSize } from '../../hooks';
import { getFuelCardsColumns } from '../main/columns';
import { FuelCards, IPart } from '../../interfaces';
import {
	balanceReportFuelCard,
	createFuelCards,
	downloadFuelCard,
	getFuelCards,
	refundFuelCard,
} from '../../services/apiService';
import moment from 'moment';
import { downloadPDF, downloadXLSX } from '../../services/fileDownload';
import { ReloadBtn } from '../../components/ReloadBtn';
import DownloadBtn from '../../components/DownloadBtn';
import TalonHistoryModal from './components/TalonHistoryModal';
import { setLoading } from '../../store/loader/actions';
import { RootState } from '../../store';
import AccountSelect from '../../components/selects/AccountSelect';
import WalletSelect from '../../components/selects/WalletSelect';
import FuelSelect from '../../components/selects/FuelSelect';
import DownloadMoneyCirculation from '../../components/DownloadMoneyCirculation';

const { Option } = Select;

interface FuelsList {
	Количество: number;
	Номенклатура: string;
	НоменклатураКартинка: string;
	НоменклатураНаименование: string;
	Партии: IPart[];
}

export const Talons: React.FC<RouteComponentProps> = () => {
	const { width } = useWindowSize();
	const isMobile = !!width && width < 480;

	const [fuelCardsForm] = Form.useForm();

	const dispatch = useDispatch();

	const { accountsList, wallet, wallets, account, walletAccounts } = useContext(
		AppContext,
	) as AppContextInterface;
	const [columnsList, setColumnsList] = useState<any[]>([]);
	const { loading } = useSelector((state: RootState) => state.loader);
	const [fuelCards, setFuelCards] = useState<Array<FuelCards>>([]);
	const [filteredFuelCards, setFilteredFuelCards] = useState<Array<FuelCards>>(
		[],
	);
	const [fuelCardsColumns, setFuelCardsColumns] = useState<Array<any>>([]);
	const [showGetFuelCardsModalModal, setShowGetFuelCardsModal] =
		useState<boolean>(false);
	const [invoiceFuel, setInvoiceFuel] = useState<string | undefined>();
	const [user, setUser] = useState<string>();
	const [partsList, setPartsList] = useState<Array<IPart>>();
	const [fuelsList, setFuelsList] = useState<FuelsList[] | null>([]);
	const [selectedPart, setSelectedParts] = useState<string>();
	const [maxCount, setMaxCount] = useState<number>(0);
	const [countByOne, setCountByOne] = useState<number>();
	const [talonsCount, setTalonsCount] = useState<number>(0);
	const [mail, setMail] = useState<string>('');
	const [filteredUser, setFilteredUser] = useState<string>('');
	const [filteredStatus, setFilteredStatus] = useState<string>('');
	const [maxDate, setMaxDate] = useState<string>();
	const [expireDate, setExpireDate] = useState<string>();
	const [uuid, setUuid] = useState<string>();
	const [refundUser, setRefundUser] = useState<FuelCards>();
	const [showRefundModal, setShowRefundModal] = useState<boolean>(false);
	const [showTransactions, setShowTransactions] = useState<boolean>(false);
	const [transactionData, setTransactionData] = useState<
		FuelCards | undefined
	>();
	const [clearFuelFilter, setClearFuelFilter] = useState<boolean>(false);

	const openNotification = (type: string, message: string) => {
		if (type === 'success') {
			Modal.success({
				title: 'Успішно',
				content: message,
				centered: true,
			});
		}
	};

	const handleChangeAccount = (data: string) => {
		localStorage.removeItem('wallet');
		localStorage.setItem('account', data);
		dispatch(setAccount(data));
		setFilteredFuelCards([]);
	};

	const selectWallet = (wallet: string) => {
		localStorage.setItem('wallet', wallet);
		dispatch(setWallet(wallet));
		setFilteredFuelCards([]);
		fuelCardsForm.resetFields([
			'user',
			'fuel',
			'part',
			'term',
			'count',
			'countByOne',
		]);
		setInvoiceFuel(undefined);
		setFuelsList([]);
		setUser('');
	};

	const wrapperGetFuelCards = () => {
		dispatch(setLoading(true));
		getFuelCards(wallet)
			.then((res) => {
				dispatch(setLoading(false));
				const invoices = res.Result;
				setFuelCards(
					invoices.map((inv: any, index: number) => {
						return {
							key: index,
							...inv,
						};
					}),
				);
			})
			.catch((err) => console.log(err));
	};

	useEffect(() => {
		if (wallet) {
			wrapperGetFuelCards();
		}
	}, [wallet]); // eslint-disable-line react-hooks/exhaustive-deps

	const onGetFuelCards = () => {
		if (
			expireDate &&
			wallet &&
			user &&
			selectedPart &&
			countByOne &&
			invoiceFuel
		) {
			createFuelCards(
				expireDate,
				wallet,
				user,
				selectedPart,
				mail,
				countByOne,
				talonsCount,
				invoiceFuel,
			).then((res) => {
				if (res.Status === 0) {
					openNotification('success', res.Result.Message);
				}
				if (res.Status !== 0) {
					Modal.error({
						title: 'Помилка',
						content: res.Error,
						centered: true,
					});
				}
			});
			setShowGetFuelCardsModal(false);
			fuelCardsForm.resetFields();
			setPartsList([]);
			setFuelsList([]);
			setUser('');
		}
	};

	const onConfirmRefund = () => {
		refundFuelCard(uuid).then((res) => wrapperGetFuelCards());
		setShowRefundModal(false);
	};

	const refund = (uuid: string, data: FuelCards) => {
		setUuid(uuid);
		setRefundUser(data);
		setShowRefundModal(true);
	};

	const download = (uuid: string) => {
		downloadFuelCard(uuid).then((res) =>
			downloadPDF(res.Result.DOCUMENT, res.Result.FileName),
		);
	};

	useEffect(() => {
		if (walletAccounts) {
			setFuelCardsColumns(getFuelCardsColumns(walletAccounts, refund, download));
		}
	}, [walletAccounts]);

	const closeFuelCardsModal = () => {
		setShowGetFuelCardsModal(false);
	};

	const openGetFuelCardsModal = () => {
		setShowGetFuelCardsModal(true);
	};

	const columnsFuelCardsMobile = fuelCardsColumns.slice(0, 2);
	const columnsFuelCardsExpand = fuelCardsColumns.slice(2);

	const parseDate = (date: string) => moment(date).utc().format(FORMAT_DATE);

	useEffect(() => {
		const selectedUser = walletAccounts?.Аккаунты.find(
			(acc) => acc.АккаунтНомерТелефона === user,
		);
		if (selectedUser) {
			setFuelsList(selectedUser.Балансы);
		}
	}, [user]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const selectedFuel = fuelsList?.find(
			(fuel) => fuel.Номенклатура === invoiceFuel,
		);
		setPartsList(selectedFuel?.Партии);
	}, [invoiceFuel]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const selectedParts = partsList?.find(
			(part) => part.ДокументПартии === selectedPart,
		);
		if (selectedParts && countByOne) {
			setMaxCount(Number((selectedParts?.Количество / countByOne).toFixed(0)));
		}
	}, [partsList, countByOne]); // eslint-disable-line react-hooks/exhaustive-deps

	const onCountSet = (event: any) => {
		setTalonsCount(event.target.value);
	};

	const onSetMail = (event: any) => {
		setMail(event.target.value);
	};

	useEffect(() => {
		const selectedParts = partsList?.find(
			(part) => part.ДокументПартии === selectedPart,
		);
		if (selectedParts) {
			setMaxDate(
				moment(selectedParts['СрокДействия'])
					.toDate()
					.toISOString()
					.split('T')[0],
			);
		}
	}, [selectedPart]); // eslint-disable-line react-hooks/exhaustive-deps

	const dropFilters = () => {
		setFilteredFuelCards([]);
		setFilteredStatus('');
		setFilteredUser('');
		setColumnsList([]);
		setClearFuelFilter(true);
	};

	useEffect(() => {
		setFilteredFuelCards(
			fuelCards.filter(
				(card) =>
					(columnsList.length
						? columnsList.includes(card.Номенклатура)
						: true) &&
					(filteredUser.length ? card.Аккаунт === filteredUser : true) &&
					(filteredStatus.length ? card.Состояние === filteredStatus : true),
			),
		);
		setClearFuelFilter(false);
	}, [columnsList, filteredUser, filteredStatus]); // eslint-disable-line react-hooks/exhaustive-deps

	const openTransactionModal = (data: FuelCards) => {
		setTransactionData(data);
		setShowTransactions(true);
	};

	// Download report based on the report type.
	const downloadReport = (reportType: string, period?: [string, string]) => {
		balanceReportFuelCard(wallet, reportType, period)
			.then((res) => {
				downloadXLSX(res.Result.DOCUMENT, res.Result.FileName);
			})
			.catch((err) => console.log(err));
	};

	return (
		<Spin spinning={loading}>
			<Row
				gutter={[16, 16]}
				align="top"
				justify="space-between"
				className="height-auto overflow-auto term"
			>
				<Col className="left">
					<AccountSelect />

					<div className="bottom-select">
						<div className="ant-col">
							<label>Користувач</label>
						</div>
						<Select
							onChange={(v) => {
								setFilteredUser(v);
							}}
							value={filteredUser}
							className="select-width"
							placeholder="Обрати користувача"
						>
							{walletAccounts?.Аккаунты.map((item, i) => (
								<Option key={`account_${i}`} value={item.Аккаунт}>
									{item.АккаунтНаименование}
								</Option>
							))}
						</Select>
					</div>
				</Col>
				<Col>
					<WalletSelect />

					<div className="bottom-select">
						<div className="ant-col">
							<label>Статус</label>
						</div>
						<Select
							placeholder="Обрати статус"
							className="select-width"
							getPopupContainer={(node) => node.parentNode}
							onChange={(value) => {
								setFilteredStatus(value);
							}}
							value={filteredStatus}
						>
							<Option value={'УКлиента'}>У клієнта</Option>
							<Option value={'Повернутий на акаунт'}>Повернутий на акаунт</Option>
							<Option value={'Отоваренный'}>Отоварений</Option>
						</Select>
					</div>
				</Col>
				<Col>
					<FuelSelect
						onChange={setColumnsList}
						useFuelKey={false}
						clear={clearFuelFilter}
					/>
					<div className="bottom-select">
						<Space size={10} align="start">
							<Button
								className="drop-button drop-filter"
								type="dashed"
								icon={<CloseCircleOutlined />}
								onClick={dropFilters}
							>
								Скинути фільтри
							</Button>
							<ReloadBtn
								btnText="Оновити"
								onClickAction={wrapperGetFuelCards}
							/>
						</Space>

					</div>
				</Col>
				<Col>
					<div className="select-flex flex-end">
						<Button className="add-user-btn" onClick={openGetFuelCardsModal}>
							+ Згенерувати одноразові талони
						</Button>
					</div>

					<div className="bottom-select">
						<div className="ant-col">
							<Space size={10} align="start">
								<DownloadBtn
									onClickAction={() => downloadReport('ОтчетПоОстаткамОТ')}
									btnText="Звіт по залишкам"
								/>
								<DownloadMoneyCirculation reportType={'ОтчетПоОборотамОТ'} downloadReport={downloadReport} />
							</Space>
						</div>
					</div>
				</Col>
				<Table
					columns={
						width && width < 480 ? columnsFuelCardsMobile : fuelCardsColumns
					}
					locale={{
						triggerDesc: 'Сортувати за спаданням',
						triggerAsc: 'Сортувати за зростанням',
						cancelSort: 'Скасувати сортування',
					}}
					dataSource={
						filteredUser.length || filteredStatus.length || columnsList.length
							? filteredFuelCards
							: fuelCards
					}
					size="small"
					pagination={{
						showSizeChanger: true,
						pageSizeOptions: ['10', '25', '50', '100'],
						defaultPageSize: 10,
					}}
					className="table-full-width term-table"
					scroll={{ x: 'max-content' }}
					expandable={
						isMobile
							? {
								expandedRowRender: (v) =>
									columnsFuelCardsExpand.map((item, index) => (
										<div className="expandable-wrap" key={index}>
											<div>{item.title}</div>
											<div className="green">
												{item.render(v[item.key as keyof FuelCards], v)}
											</div>
										</div>
									)),
								columnWidth: 33,
							}
							: undefined
					}
					onRow={(part) => {
						return {
							onClick: () => openTransactionModal(part),
						};
					}}
				/>
			</Row>
			{/******************** GET FUEL CARDS MODAL ********************/}
			<Modal
				title={'Згенерувати одноразові талони'}
				footer={null}
				className="invoice"
				width="800"
				visible={showGetFuelCardsModalModal}
				onCancel={closeFuelCardsModal}
			>
				<div className="details">
					<Form form={fuelCardsForm} onFinish={onGetFuelCards}>
						<div className="details-item">
							<span>Організація</span>
							<Form.Item className="fuel-invoice-select">
								<Select
									onChange={handleChangeAccount}
									value={account}
									className="fuel-select"
								>
									{accountsList.map((item, i) => (
										<Option key={`account_${i}`} value={item['Контрагент']}>
											{item['КонтрагентНаименование']}
										</Option>
									))}
								</Select>
							</Form.Item>
						</div>
						<div className="details-item">
							<span>Рахунок</span>
							<Form.Item className="fuel-invoice-select">
								<Select
									className="fuel-select"
									onSelect={selectWallet}
									value={wallet}
								>
									{wallets.map((item, index) => (
										<Option key={`wallets_${index}`} value={item['Счет']}>
											{item['СрокДействияИстикает'] ? (
												<span className="warning">
                          {item['СчетНаименование']}
													<ExclamationOutlined />
                        </span>
											) : (
												item['СчетНаименование']
											)}
										</Option>
									))}
								</Select>
							</Form.Item>
						</div>
						<div className="details-item">
							<span>Користувач</span>
							<Form.Item
								name="user"
								className="fuel-invoice-select"
								rules={[{ required: true, message: 'Оберіть користувача' }]}
							>
								<Select
									onChange={(value) => {
										setUser(value);
										fuelCardsForm.resetFields([
											'fuel',
											'part',
											'term',
											'count',
											'countByOne',
										]);
									}}
									value={user}
									className="fuel-select"
									placeholder="Обрати користувача"
								>
									{walletAccounts?.Аккаунты.map((item, i) => (
										<Option
											key={`account_${i}`}
											value={item.АккаунтНомерТелефона}
										>
											{item.АккаунтНаименование}
										</Option>
									))}
								</Select>
							</Form.Item>
						</div>
						<div className="details-item">
							<span>Нафтопродукт</span>
							<Form.Item
								name="fuel"
								className="fuel-invoice-select"
								rules={[{ required: true, message: 'Оберіть тип пального' }]}
							>
								<Select
									placeholder="Обрати номенклатуру"
									onChange={(value) => {
										setInvoiceFuel(value);
										fuelCardsForm.resetFields([
											'part',
											'term',
											'count',
											'countByOne',
										]);
										setCountByOne(0);
									}}
									className="fuel-select"
									value={invoiceFuel}
									getPopupContainer={(node) => node.parentNode}
									disabled={!user}
								>
									{fuelsList?.map((fuel: any, i: any) => (
										<Option value={fuel.Номенклатура} key={`transfer_${i}`}>
											{fuel.НоменклатураНаименование}
										</Option>
									))}
								</Select>
							</Form.Item>
						</div>
						<div className="details-item">
							<span>Партія</span>
							<Form.Item
								name="part"
								className="fuel-invoice-select"
								rules={[{ required: true, message: 'Оберіть партію' }]}
							>
								<Select
									placeholder="Обрати партію"
									className="fuel-select"
									getPopupContainer={(node) => node.parentNode}
									onChange={(value) => {
										setSelectedParts(value as string);
										fuelCardsForm.resetFields(['term', 'count', 'countByOne']);
										setCountByOne(0);
									}}
									disabled={!invoiceFuel}
								>
									{partsList &&
										partsList.map((i) => {
											return (
												<Option
													value={i['ДокументПартии']}
													key={i['ДокументПартии']}
												>
													{`${parseDate(i['СрокДействия'])} - ${
														i['Количество']
													}`}
													<span className="dem">л.</span>
												</Option>
											);
										})}
								</Select>
							</Form.Item>
						</div>
						<div className="details-item">
							<span>Термін дії</span>
							<Form.Item
								name="term"
								className="fuel-invoice-select"
								rules={[{ required: true, message: 'Оберіть термін дії' }]}
							>
								<Input
									disabled={!selectedPart}
									placeholder="оберіть дату"
									className="fuel-select"
									max={maxDate}
									type="date"
									onChange={(value) => {
										setExpireDate(value.target.value);
									}}
								/>
							</Form.Item>
						</div>
						<div className="details-item">
							<span>Номінал</span>
							<Form.Item
								name="countByOne"
								className="fuel-invoice-select"
								rules={[{ required: true, message: 'Оберіть тип пального' }]}
							>
								<Select
									placeholder="Обрати номінал"
									className="fuel-select"
									getPopupContainer={(node) => node.parentNode}
									onChange={(value) => {
										setCountByOne(value as number);
										fuelCardsForm.resetFields(['count']);
										setTalonsCount(0);
									}}
									disabled={!invoiceFuel}
								>
									<Option value={5}>5л.</Option>
									<Option value={10}>10л.</Option>
									<Option value={15}>15л.</Option>
									<Option value={20}>20л.</Option>
									<Option value={25}>25л.</Option>
									<Option value={30}>30л.</Option>
									<Option value={35}>35л.</Option>
									<Option value={40}>40л.</Option>
									<Option value={45}>45л.</Option>
									<Option value={50}>50л.</Option>
								</Select>
							</Form.Item>
						</div>
						<div className="details-item">
							<span>Кількість талонів</span>
							<Form.Item
								name="count"
								className="fuel-invoice-select"
								rules={[
									{ required: true, message: 'Введіть кількість талонів' },
									{
										validator: (_, value) =>
											value === undefined || value === '' || +value <= maxCount
												? Promise.resolve()
												: Promise.reject(
													`Максимальна кількість - ${maxCount} штук`,
												),
									},
								]}
							>
								<Input
									className="fuel-select"
									value={talonsCount}
									type="number"
									max={maxCount}
									onChange={onCountSet}
								/>
							</Form.Item>
						</div>
						<Form.Item>
							<div className="details-item">
								<span>Загальна кількість літрів</span>
								<span>
										{countByOne && talonsCount
											? `${countByOne * talonsCount}л.`
											: ''
										}
								</span>
							</div>
						</Form.Item>
						<div className="details-item">
							<span>Email</span>
							<Form.Item
								name="mail"
								className="fuel-invoice-select"
								rules={[
									{ required: true, message: 'Введіть email' },
									{ type: 'email', message: 'Введіть коректний email' },
								]}
							>
								<Input
									className="fuel-select"
									type="mail"
									onChange={onSetMail}
								/>
							</Form.Item>
						</div>
						<Form.Item>
							<div className="invoice-submit">
								<Button type="primary" htmlType="submit">
									+ Згенерувати одноразові талони
								</Button>
							</div>
						</Form.Item>
					</Form>
				</div>
			</Modal>
			{/******************** REFUND MODAL ********************/}
			<Modal
				title={'Повернути на залишок'}
				visible={showRefundModal}
				onOk={() => {
					onConfirmRefund();
				}}
				onCancel={() => {
					setShowRefundModal(false);
				}}
				okText="Повернути"
				cancelText="Ні"
			>
				<div className="refund-info-container">
          <span>
            {`Невикористаний залишок буде повернуто на рахунок користувача, з якого він був сформований`}
          </span>
					<span>
            {`Користувач ${
							walletAccounts?.Аккаунты.find(
								(acc) => acc.Аккаунт === refundUser?.Аккаунт,
							)?.АккаунтНаименование
						} - залишок ${
							Number(refundUser?.Количество) -
							Number(refundUser?.КоличествоОтоварено)
						}л.`}
          </span>
				</div>
			</Modal>
			<TalonHistoryModal
				showModal={[showTransactions, setShowTransactions]}
				data={transactionData}
			/>
		</Spin>
	);
};
