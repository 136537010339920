import { ColumnsType } from 'antd/es/table';
import {
	IContractItem,
	INomenclatureItem,
	INominalItem,
	IPartyItem,
	IPlasticCardItem,
	IPlasticCardTransactionItem,
} from '../types';
import React from 'react';
import moment from 'moment/moment';
import { FORMAT_DATE } from '../../../constants';
import { Typography } from 'antd';

const { Text } = Typography;

export const getNomenclatureTableColumns = (indexes: number[] | false): ColumnsType<INomenclatureItem> => {
	const columns: ColumnsType<INomenclatureItem> = [
		{
			title: 'Вид пального',
			dataIndex: 'name',
			key: 'nomenclatureName',
			width: '50%',
		}, {
			title: 'Договір контрагента',
			dataIndex: '',
			key: 'nomenclatureEmpty',
			width: '50%',
		}, {
			title: 'Партія',
			dataIndex: '',
			key: 'nomenclatureEmpty2',
			width: '50%',

		}, {
			title: 'Мінімальний термін дії',
			dataIndex: '',
			key: 'nomenclatureEmpty3',
			width: '50%',

		}, {
			title: 'Кількість',
			dataIndex: 'quantity',
			key: 'quantity',
			width: 150,
			sorter: {
				compare: (a: INomenclatureItem, b: INomenclatureItem) =>
					a['quantity'] - b['quantity'],
			},
		}];

	return indexes ? indexes.map(index => columns[index]).filter(Boolean) : columns;
};

export const getContractTableColumns = (indexes: number[] | false, nomenclatureName: string): ColumnsType<IContractItem> => {
	const columns: ColumnsType<IContractItem> = [
		{
			title: 'Вид пального',
			dataIndex: '',
			key: 'contractEmpty',
			width: '50%',
			render: () => <Text>{nomenclatureName}</Text>,
		}, {
			title: 'Договір контрагента',
			dataIndex: 'name',
			key: 'contractName',
			width: indexes ? '100%' : '50%',
		}, {
			title: 'Партія',
			dataIndex: 'party',
			key: 'contractParty',
			width: '50%',
		}, {
			title: 'Мінімальний термін дії',
			dataIndex: '',
			key: 'contractEmpty2',
			width: '50%',
		}, {
			title: 'Кількість',
			dataIndex: 'quantity',
			key: 'contractQuantity',
			width: 150,
		}];

	return indexes ? indexes.map(index => columns[index]).filter(Boolean) : columns;
};

export const getPartyTableColumns = (indexes: number[] | false, nomenclatureName: string, contractName: string): ColumnsType<IPartyItem> => {
	const columns: ColumnsType<IPartyItem> = [
		{
			title: 'Вид пального',
			dataIndex: '',
			key: 'partyEmpty',
			width: '50%',
			render: () => <Text>{nomenclatureName}</Text>,
		}, {
			title: 'Договір контрагента',
			dataIndex: '',
			key: 'partyEmpty2',
			width: '50%',
			render: () => <Text>{contractName}</Text>,
		}, {
			title: 'Партія',
			dataIndex: 'name',
			key: 'partyName',
			width: '50%',
		}, {
			title: 'Мінімальний термін дії',
			dataIndex: 'minValidityPeriod',
			key: 'minValidityPeriod',
			width: '50%',
			render: (v: string) => moment(v).utc().format(FORMAT_DATE),
		}, {
			title: 'К-ть',
			dataIndex: 'quantity',
			key: 'partyQuantity',
			width: 150,
		}];

	return indexes ? indexes.map(index => columns[index]).filter(Boolean) : columns;
};

export const getNominalsColumns = (indexes: number[] | false): ColumnsType<INominalItem> => {
	const columns: ColumnsType<INominalItem> = [
		{
			title: 'Номінал',
			dataIndex: 'nominal',
			key: 'nominal',
			width: 150,
		}, {
			title: 'Талон',
			dataIndex: 'empty',
			key: 'nominalEmpty',
		}, {
			title: 'Статус',
			dataIndex: 'empty',
			key: 'nominalEmpty2',
		}, {
			title: 'Термін дії',
			dataIndex: 'expiryDate',
			key: 'expiryDate',
			render: (v: string) => moment(v).utc().format(FORMAT_DATE),
		}, {
			title: 'К-ть талонів',
			dataIndex: 'plasticCardsQuantity',
			key: 'plasticCardsQuantity',
			sorter: {
				compare: (a: INominalItem, b: INominalItem) =>
					a['plasticCardsQuantity'] - b['plasticCardsQuantity'],
			},
		}, {
			title: 'К-ть літрів',
			dataIndex: 'litersQuantity',
			key: 'litersQuantity',
			sorter: {
				compare: (a: INominalItem, b: INominalItem) =>
					a['litersQuantity'] - b['litersQuantity'],
			},
		}];

	return indexes ? indexes.map(index => columns[index]).filter(Boolean) : columns;
};

export const getPlasticCardsColumns = (indexes: number[] | false): ColumnsType<IPlasticCardItem> => {
	const columns: ColumnsType<IPlasticCardItem> = [
		{
			title: 'Номінал',
			dataIndex: 'empty',
			key: 'plasticCardEmpty',
			width: 150,
		}, {
			title: 'Талон',
			dataIndex: 'cardName',
			key: 'cardName',
		}, {
			title: 'Статус',
			dataIndex: 'status',
			key: 'status',
		}, {
			title: 'Термін дії',
			dataIndex: 'expiryDate',
			key: 'expiryDate',
			render: (v: string) => moment(v).utc().format(FORMAT_DATE),
		}, {
			title: 'К-ть талонів',
			dataIndex: 'plasticCardsQuantity',
			key: 'plasticCardEmpty2',
		}, {
			title: 'К-ть літрів',
			dataIndex: 'quantity',
			key: 'quantity',
		}];

	return indexes ? indexes.map(index => columns[index]).filter(Boolean) : columns;
};

export const getPlasticCardTransactionsColumns = (): ColumnsType<IPlasticCardTransactionItem> => ([{
	title: 'Дата',
	dataIndex: 'date',
	key: 'date',
	width: 120,
	render: (v: string) => moment(v).format('DD.MM.YYYY HH:mm'),
}, {
	title: 'Талон',
	dataIndex: 'talon',
	key: 'talon',
	width: 160,
	render: (v: string) => v,
}, {
	title: 'Вид пального',
	dataIndex: 'nomenclatureNameShort',
	key: 'nomenclatureNameShort',
	width: 110,
	render: (v: string) => v,
}, {
	title: 'К-ть',
	dataIndex: 'amount',
	key: 'amount',
	width: 100,
	render: (v: string) => v,
}, {
	title: 'Адреса',
	dataIndex: 'address',
	key: 'address',
	render: (v: string) => v,
}, {
	title: 'Договір',
	dataIndex: 'contractName',
	key: 'contractName',
	width: 200,
	render: (v: string) => v,
}, {
	title: 'Партія',
	dataIndex: 'partyName',
	key: 'partyName',
	width: 205,
	render: (v: string) => v,
}]);