import React, { FC, useEffect, useState } from 'react';
import { Button, Form, Input, Modal, Select, Space, Spin, Table, Tooltip, Typography } from 'antd';
import { ICommonModalComponentProps } from '../../../interfaces';
import { getRecountBalance, postSelectedRecountBalance } from '../../../services/apiService';
import { recountDataAdapter, recountDataPricesAdapter } from '../../plasticCards/adapters';
import { IRecountDataItem, IRecountPriceItem } from '../../plasticCards/types';
import { getRecountTableColumns } from './tableColumns';
import { TableProps } from 'antd/es/table';
import { Controller, useForm } from 'react-hook-form';
import { LabeledValue } from 'antd/es/select';
import openNotification from '../../../services/openNotification';
import { RollbackOutlined, SwapOutlined } from '@ant-design/icons';

const { Text } = Typography;

interface IRecountBalanceModalProps extends ICommonModalComponentProps {
	walletId?: string;
}

interface IRecountFormFields {
	nomenclature: string,
	amount: string,
}

const RecountBalanceModal: FC<IRecountBalanceModalProps> = ({ isModalOpen, toggleModal, walletId }) => {
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [tableData, setTableData] = useState<IRecountDataItem[]>([]);
	const [selectedTableRows, setSelectedTableRows] = useState<IRecountDataItem[]>([]);
	const [prices, setPrices] = useState<IRecountPriceItem[]>([]);
	const [availableTargetNomenclatures, setAvailableTargetNomenclatures] = useState<LabeledValue[]>([]);
	const [confirmModalType, setConfirmModalType] = useState<'Return' | 'Recount'>();

	const { control, watch, getValues, handleSubmit, reset } = useForm<IRecountFormFields>();
	const nomenclatureFieldValue = watch('nomenclature');

	/**
	 * Initial Data fetch.
	 */
	useEffect(() => {
		if (!walletId) return;

		getRecountBalance(walletId)
			.then(({ Result }) => {
				if (!Result) return;
				const { ДанныеПересчета: recountData, Цены: prices } = Result;
				const mappedRecountData = recountDataAdapter(recountData);
				const mappedPrices = recountDataPricesAdapter(prices);

				setTableData(mappedRecountData);
				setPrices(mappedPrices);
			})
			.catch((err) => console.log(err))
			.finally(() => setIsLoading(false));
	}, [walletId]);

	/**
	 * Set nomenclatures select data.
	 */
	useEffect(() => {
		if (!prices) return;

		const mappedPrices =
			prices.map(({ nomenclatureNameShort, nomenclature }) => ({ label: nomenclatureNameShort, value: nomenclature }));

		setAvailableTargetNomenclatures(mappedPrices);
	}, [prices]);

	/**
	 * Watch nomenclature field change.
	 */
	useEffect(() => {
		if (!nomenclatureFieldValue) return;

		const selectedNomenclaturePrice = prices.find(({ nomenclature }) => nomenclature === nomenclatureFieldValue)?.price;

		if (!selectedNomenclaturePrice) return;

		const result = selectedTableRows.reduce((sum, item) => sum + item.leftOver * item.price / selectedNomenclaturePrice, 0);

		reset({ ...getValues(), amount: `${result.toFixed(2)} л.` });
	}, [nomenclatureFieldValue, selectedTableRows]);

	/**
	 * ANTD row selection handler.
	 */
	const rowSelection: TableProps<IRecountDataItem>['rowSelection'] = {
		onChange: (selectedRowKeys: React.Key[], selectedRows: IRecountDataItem[]) => {
			setSelectedTableRows(selectedRows);
		},
	};

	/**
	 * Submit selected changes to server.
	 */
	const postData = () => {
		const targetNomenclature = availableTargetNomenclatures.find(({ value }) => value === getValues('nomenclature'));
		const data = {
			selectedParties: selectedTableRows.map((
				{
					nomenclature,
					nomenclatureNameShort,
					nomenclatureName,
					leftOver,
					partyName,
					partyId,
					price,
					currentPrice,
				},
			) => ({
				НоменклатураУИД: nomenclature,
				СокращенноеОбозначение: nomenclatureNameShort,
				НоменклатураНаименование: nomenclatureName,
				Партия: partyName,
				ПартияУИД: partyId,
				Остаток: leftOver,
				Цена: price,
				ЦенаТекущая: currentPrice,
			})),
			isReturn: confirmModalType === 'Return',
			targetNomenclatureId: targetNomenclature?.value as string,
			targetNomenclatureName: targetNomenclature?.label as string,
			amount: parseFloat(getValues('amount')),
		};

		postSelectedRecountBalance(
			data.selectedParties,
			data.isReturn,
			data.targetNomenclatureId,
			data.targetNomenclatureName,
			data.amount,
		)
			.then(({ Result }) => {
				if (!Result) return;

				setConfirmModalType(undefined);
				openNotification('success', 'Заявку успішно передано.');
				toggleModal();
			})
			.catch((err) => console.log(err))
			.finally(() => setIsLoading(false));
	};

	return (
		<Modal className="wrapper"
					 width={'100%'}
					 visible={isModalOpen}
					 onCancel={toggleModal}
					 footer={false}>
			<Spin spinning={isLoading}>
				<Space direction="vertical" size={'middle'}>
					<Space direction="vertical" size={'middle'}>
						<Text>1. Оберіть партії які ви хочете перерахувати.</Text>
						<Table
							rowSelection={{ type: 'checkbox', ...rowSelection }}
							tableLayout="fixed"
							pagination={false}
							dataSource={tableData}
							columns={getRecountTableColumns()} />
					</Space>

					<Space direction="vertical" size="middle">
						<Text>2. Оберіть вид пального для перерахунку кількості літрів по вибраним партіям..</Text>

						{/* INPUTS. */}
						<Space align="start">
							{/* Nomenclature. */}
							<Controller
								name="nomenclature"
								control={control}
								render={({ field: { value, onChange }, fieldState: { error } }) => (
									<Form.Item validateStatus={error && 'error'}>
										<Select
											style={{ width: 270 }}
											placeholder="Вид пального"
											showSearch
											value={value}
											options={availableTargetNomenclatures || []}
											onChange={onChange}
										/>
									</Form.Item>
								)}
							/>

							{/* Amount. */}
							<Controller
								name="amount"
								control={control}
								render={({ field: { value } }) => (
									<Input
										disabled
										type="text"
										style={{ width: 120 }}
										placeholder="К-ть літрів"
										value={value}
									/>
								)}
							/>
						</Space>

						{/* CTA BUTTONS. */}
						<Space size="middle">
							<Tooltip
								title={!nomenclatureFieldValue || !selectedTableRows.length ? 'Оберіть партії та вид пального для перерахунку' : null}>
								<Button disabled={!nomenclatureFieldValue || !selectedTableRows.length}
												type="primary"
												onClick={handleSubmit(() => setConfirmModalType('Recount'))}>
									<SwapOutlined />
									Перерахувати
								</Button>
							</Tooltip>

							<Tooltip title={!selectedTableRows.length ? 'Оберіть партії для повернення' : null}>
								<Button
									disabled={!selectedTableRows.length}
									type="primary"
									onClick={handleSubmit(() => setConfirmModalType('Return'))}>
									<RollbackOutlined />
									Повернути
								</Button>
							</Tooltip>

						</Space>
					</Space>
				</Space>
			</Spin>
			<Modal
				title={
					confirmModalType === 'Recount'
						? 'Перерахувати пальне?'
						: confirmModalType === 'Return'
							? 'Повернути пальне?'
							: null
				}
				visible={!!confirmModalType}
				onOk={postData}
				onCancel={() => setConfirmModalType(undefined)}>
				<Text>Дані про перерахунок вибраних партій буде перердано менеджеру</Text>
			</Modal>
		</Modal>
	);
};

export default RecountBalanceModal;