import { ColumnsType } from 'antd/es/table';
import { IRecountDataItem } from '../../plasticCards/types';

export const getRecountTableColumns = (): ColumnsType<IRecountDataItem> => ([{
	title: 'Партія',
	dataIndex: 'partyName',
	key: 'partyName',
	width: 210,
	render: (v: string) => v,
}, {
	title: 'Вид пального',
	dataIndex: 'nomenclatureNameShort',
	key: 'nomenclatureNameShort',
	render: (v: string) => v,
}, {
	title: 'Ціна',
	dataIndex: 'price',
	key: 'price',
	render: (v: string) => v,
}, {
	title: 'Актуальна ціна',
	dataIndex: 'currentPrice',
	key: 'currentPrice',
	render: (v: string) => v,
}, {
	title: 'Залишок',
	dataIndex: 'leftOver',
	key: 'leftOver',
	width: 120,
	render: (v: string) => v,
}]);