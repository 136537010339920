import React, { useState, useMemo } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Layout, Menu, Dropdown, Alert } from 'antd'
import {
  LogoutOutlined,
  SettingOutlined,
  MenuFoldOutlined,
  MenuOutlined,
  WalletOutlined,
  FieldTimeOutlined,
  ExclamationOutlined,
  ShoppingCartOutlined,
  CreditCardOutlined,
} from '@ant-design/icons'
import { ReactComponent as Logo } from '../../assets/logo.svg'
import { ReactComponent as Ticket } from '../../assets/ticket.svg'
import { Logout } from '../../services/apiService'
import { RootState } from '../../store'
import { useWindowSize } from '../../hooks'
import { AppProvider, AppContext } from '../../AppProvider'

const { Header, Content, Sider, Footer } = Layout

interface ICommonLayout {
  children: React.Component
}

const CommonLayout: React.FC<ICommonLayout> = ({ children }) => {
  const {
    location: { pathname },
  } = useHistory()
  const { user_name } = useSelector((state: RootState) => state.account)
  const { width } = useWindowSize()
  const [collapsed, setCollapsed] = useState<boolean>(true)
  const [isMobile, setIsMobile] = useState<boolean>(false)
  const userInfo = localStorage.getItem('user')

  const currentYear = new Date().getFullYear()

  useMemo(() => {
    const isMobileCheck = !!(width && width > 0 && width < 575)
    setIsMobile(isMobileCheck)
  }, [width])

  const onCollapse = () => {
    setCollapsed(!collapsed)
  }

  const hideMenu = () => {
    if (isMobile) {
      setCollapsed(true)
    }
  }

  const settingsMenu = (
    <Menu>
      <Menu.Item onClick={() => Logout()}>
        <LogoutOutlined /> Вихід
      </Menu.Item>
    </Menu>
  )

  return (
    <AppProvider>
      <AppContext.Consumer>
        {({ termExpires }) => (
          <div className="wrapper">
            <Sider
              trigger={null}
              collapsible
              collapsed={collapsed}
              theme="light"
            >
              <div className="sider-logo">
                <Logo />
              </div>
              <Menu selectedKeys={[pathname]} mode="inline" onClick={hideMenu}>
                <Menu.Item key="/main">
                  <WalletOutlined />
                  <Link to="/main">Баланс</Link>
                </Menu.Item>
                <Menu.Item key="/term">
                  <FieldTimeOutlined className={termExpires ? 'warning' : ''} />
                  <Link to="/term" className={termExpires ? 'warning' : ''}>
                    Термін дії
                    {termExpires && <ExclamationOutlined />}
                  </Link>
                </Menu.Item>
                <Menu.Item key="/buy">
                  <ShoppingCartOutlined />
                  <Link to="/buy">Покупка</Link>
                </Menu.Item>

                {userInfo && JSON.parse(userInfo).ОдноразовыеТалоны ? (
                  <Menu.Item key="/talons">
                    <Ticket className="ticket" />
                    <Link to="/talons">Одноразові талони</Link>
                  </Menu.Item>
                ) : null}

                {userInfo && JSON.parse(userInfo).ПластиковыеТалоны ? (
                    <Menu.Item key="/plastic-cards">
                      <CreditCardOutlined />
                      <Link to="/plastic-cards">Пластикові талони</Link>
                    </Menu.Item>
                ) : null}

                <Menu.SubMenu title="Інформація">
                  <Menu.Item key={'/admins'}>
                    <Link to="/admins">Адміністратори</Link>
                  </Menu.Item>
                  <Menu.Item key={'/contacts'}>
                    <Link to="/contacts">Контакти менеджера</Link>
                  </Menu.Item>
                  <Menu.Item key={'/instructions'}>
                    <Link to="/instructions">Інструкції</Link>
                  </Menu.Item>
                  <Menu.Item key={'/map'}>
                    <Link
                      to={{ pathname: 'https://wog.ua/ua/map/' }}
                      target="_blank"
                    >
                      Мапа мережі
                    </Link>
                  </Menu.Item>
                </Menu.SubMenu>
              </Menu>
            </Sider>

            <Layout>
              <Header className={!collapsed ? 'flex flex-end' : ''}>
                {isMobile &&
                  (collapsed ? (
                    <MenuOutlined onClick={onCollapse} />
                  ) : (
                    <MenuFoldOutlined onClick={onCollapse} />
                  ))}
                <div className="flex align-items-center">
                  {collapsed && (
                    <Link to="/main">
                      <div className="header-logo">
                        <Logo />
                      </div>
                    </Link>
                  )}
                  <Menu selectedKeys={[pathname]} mode="horizontal">
                    <Menu.Item key="/main">
                      <Link to="/main">Баланс</Link>
                    </Menu.Item>
                    <Menu.Item key="/term">
                      <Link to="/term" className={termExpires ? 'warning' : ''}>
                        Залишки / Терміни дії
                        {termExpires && (
                          <ExclamationOutlined className="warning" />
                        )}
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="/buy">
                      <Link to="/buy">Покупка</Link>
                    </Menu.Item>

                    {userInfo && JSON.parse(userInfo).ОдноразовыеТалоны ? (
                      <Menu.Item key="/talons">
                        <Link to="/talons">Одноразові талони</Link>
                      </Menu.Item>
                    ) : null}

                    {userInfo && JSON.parse(userInfo).ПластиковыеТалоны ? (
                        <Menu.Item key="/plastic-cards">
                          <Link to="/plastic-cards">Пластикові талони</Link>
                        </Menu.Item>
                    ) : null}

                    <Menu.SubMenu title="Інформація">
                      <Menu.Item key={'/admins'}>
                        <Link to="/admins">Адміністратори</Link>
                      </Menu.Item>
                      <Menu.Item key={'/contacts'}>
                        <Link to="/contacts">Контакти менеджера</Link>
                      </Menu.Item>
                      <Menu.Item key={'/instructions'}>
                        <Link to="/instructions">Інструкції</Link>
                      </Menu.Item>
                      <Menu.Item key={'/map'}>
                        <Link
                          to={{ pathname: 'https://wog.ua/ua/map/' }}
                          target="_blank"
                        >
                          Мапа мережі
                        </Link>
                      </Menu.Item>
                    </Menu.SubMenu>
                  </Menu>
                </div>

                <Dropdown
                  overlay={settingsMenu}
                  placement="bottomLeft"
                  trigger={['click']}
                >
                  <div>
                    <span className="greeting">{user_name}</span>
                    <SettingOutlined />
                  </div>
                </Dropdown>
              </Header>
              <Layout className="container" onClick={hideMenu}>
                <Content>{children}</Content>
              </Layout>
              {termExpires && (
                <Alert
                  className="alert-term"
                  message=""
                  description="У вас є залишок пального, термін дії якого добігає кінця."
                  showIcon
                  closable
                />
              )}
              <Footer>
                {`COPYRIGHT © ${currentYear} WOG. ALL RIGHTS RESERVED`}
              </Footer>
            </Layout>
          </div>
        )}
      </AppContext.Consumer>
    </AppProvider>
  )
}

export default CommonLayout
