import { Moment } from 'moment';
import React, { FC, useState } from 'react';
import { RangePickerProps } from 'antd/es/date-picker/generatePicker';
import { DatePicker } from 'antd';
import moment from 'moment/moment';

const { RangePicker }: any = DatePicker;

interface CustomProps {
	monthToSkip: number;
	initialTimeRange: [moment.Moment, moment.Moment];
}

type RangePickerWithPeriodLimitProps = RangePickerProps<Moment> & CustomProps;

/**
 * Custom range picker, where we define the range that will be available to choosing.
 *
 * @param monthToSkip Number of month available after selecting 1st date.
 * @param value RangePicker value.
 * @param onChange RangePicker onChange callback.
 * @param initialTimeRange Time range for initial render and Resetting.
 * @param rangePickerProps - ANTD range picker props.
 */
const RangePickerWithPeriodLimit: FC<RangePickerWithPeriodLimitProps> =
	({
		 monthToSkip,
		 value,
		 onChange,
		 initialTimeRange,
		 ...rangePickerProps
	 }) => {
		const [firstDate, setFirstDate] = useState<moment.Moment>();

		/**
		 * Custom handler of field change.
		 *
		 * // TODO: Remove once antd supports custom onClear() method usage.
		 * @param dates Form field value
		 */
		const handleChange = (dates: [moment.Moment, moment.Moment] | null) => {
			if (!onChange) return;

			// If reset.
			if (!dates) {
				setFirstDate(undefined);
				onChange(initialTimeRange, ['', '']);
			}
			// If update value.
			else {
				setFirstDate(dates[0]);

				// If updating 2d value
				if (dates[0].isSame(firstDate)) {
					onChange(dates, ['', '']);
				}
				// If updating 1st value.
				else {
					onChange([dates[0], dates[0].add('day')], ['', '']);
				}
			}
		};

		/**
		 * Determines whether a date should be disabled in the date picker.
		 *
		 * @param current - The current date being checked in the picker.
		 */
		const disabledDate = (current: any) => {
			if (!firstDate) {
				// No first date selected: Allow any date up to today
				return current > moment();
			}

			// After first date is selected: Restrict "to" date to within a passed month amount.
			const availableDateRange = firstDate.clone().add(monthToSkip, 'months').endOf('day');
			return current > availableDateRange || current < firstDate;
		};

		return (
			<RangePicker value={value}
									 onChange={handleChange}
									 disabledDate={disabledDate}
									 {...rangePickerProps} />
		);
	};

export default RangePickerWithPeriodLimit;