import React, { useState, useMemo, useEffect, useContext } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { getTermsColumns, getBalanceColumns } from '../main/columns';
import { Spin, Row, Col, Table, Modal, Space, Button } from 'antd';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useWindowSize } from '../../hooks';
import { RootState } from '../../store';
import { ITermPart, Balance } from '../../interfaces';
import { partsAdapter } from '../../services/adapters';
import {
	FUELS,
	EMPTY_GUID,
	UNDISTRIBUTED_TITLE,
	FORMAT_DATE,
} from '../../constants';
import { AppContext, AppContextInterface } from '../../AppProvider';
import { ReloadBtn } from '../../components/ReloadBtn';
import { balanceReport, checkPartPrice } from '../../services/apiService';
import ContinuationModal from './components/ContinuationModal';
import { ReactComponent as Download } from '../../assets/download.svg';
import DownloadBtn from '../../components/DownloadBtn';
import { downloadXLSX } from '../../services/fileDownload';
import AccountSelect from '../../components/selects/AccountSelect';
import WalletSelect from '../../components/selects/WalletSelect';
import FuelSelect from '../../components/selects/FuelSelect';
import RecountBalanceModal from './components/RecountBalanceModal';
import DownloadMoneyCirculation from '../../components/DownloadMoneyCirculation';


type BalanceByPart = {
	key: string
	alias: string
	count: number
}

const Term: React.FC<RouteComponentProps> = () => {
	const { wallet, walletAccounts } = useContext(
		AppContext,
	) as AppContextInterface;
	const { width } = useWindowSize();
	const { loading } = useSelector((state: RootState) => state.loader);
	const [{ columnsTerm, balanceColumns }, setColumns] = useState<{
		columnsTerm: any[]
		balanceColumns: any[]
	}>({ columnsTerm: [], balanceColumns: [] });
	const [allParts, setAllParts] = useState<Array<ITermPart>>([]);
	const [balances, setBalances] = useState<Array<Balance>>([]);
	const [partsByFuels, setPartsByFuels] = useState<Array<ITermPart>>([]);
	const [selectedFuels, setSelectedFuels] = useState<Array<string>>([]);
	const [chosenPart, setChosenPart] = useState<ITermPart>();
	const [balanceByPart, setBalanceByPart] = useState<Array<BalanceByPart>>();
	const [showModal, setShowModal] = useState<{ showBalance: boolean }>({
		showBalance: false,
	});
	const [showContinuationModal, setShowContinuationModal] =
		useState<boolean>(false);
	const [showRecountBalanceModal, setShowRecountBalanceModal] = useState<boolean>(false);
	const [document, setDocument] = useState<string>('');


	const isMobile = !!width && width < 480;

	useEffect(() => {
		if (selectedFuels.length) {
			setPartsByFuels(
				allParts.filter((item) => selectedFuels.indexOf(item.fuel) > -1),
			);
		}
	}, [selectedFuels, allParts]);

	useEffect(() => {
		if (walletAccounts) {
			setAllParts(
				partsAdapter(
					walletAccounts['Счет'].filter((item: any) => item['Партии'] !== null),
				),
			);
			setBalances(walletAccounts['Аккаунты']);
		}
	}, [walletAccounts]);

	const getBalanceByPart = (fuel: string, document: string) => {
		const data: Array<BalanceByPart> = [];
		balances.forEach((balance) => {
			balance['Балансы']?.forEach((item) => {
				item['Номенклатура'] === fuel &&
				item['Партии'].forEach((part) => {
					if (part['ДокументПартии'] === document) {
						balance['Аккаунт'] === EMPTY_GUID
							? data.unshift({
								key: balance['Аккаунт'],
								alias: UNDISTRIBUTED_TITLE,
								count: part['Количество'],
							})
							: data.push({
								key: balance['Аккаунт'],
								alias: balance['Псевдоним']
									? `${balance['АккаунтНомерТелефона']} (${balance['Псевдоним']})`
									: `${balance['АккаунтНомерТелефона']} (${balance['АккаунтИмя']} ${balance['АккаунтФамилия']})`,
								count: part['Количество'],
							});
					}
				});
			});
		});
		return data;
	};

	const showBalanceModal = (part: ITermPart) => {
		setChosenPart(part);
		setShowModal({ ...showModal, showBalance: true });
		setBalanceByPart(getBalanceByPart(part.fuel, part.document));
	};

	const continuation = (documentGuid: string) => {
		checkPartPrice(documentGuid).then((res) => {
			if (res.Status === 0 && res.Result.Разрешено) {
				setShowContinuationModal(true);
				setDocument(documentGuid);
			} else {
				Modal.warning({
					title: 'Увага!!!',
					content:
						'Натисніть на кнопку "Перерахувати партії" для перерахунку або повернення!',
					centered: true,
				});
			}
		});
	};

	const cancelContiuation = () => {
		setShowContinuationModal(false);
		setDocument('');
	};

	useMemo(() => {
		setColumns({
			columnsTerm: getTermsColumns(isMobile, continuation),
			balanceColumns: getBalanceColumns(),
		});
	}, [isMobile]);

	const downloadReport = (reportType: string, forPart: boolean = false, period?: [string, string]) => {
		const option: string = forPart ? 'ПоПартии' : 'Основной';
		balanceReport(wallet, chosenPart?.document, chosenPart?.part, option, reportType, period)
			.then((res) => {
				downloadXLSX(res.Result.DOCUMENT, res.Result.FileName);
			})
			.catch((err) => console.log(err));
	};

	// for mobile, we use two columns, the remaining columns are displayed by clicking on the expand button
	const columnsTermMobile = columnsTerm.slice(0, 2);
	const columnsTermExpanded = columnsTerm.slice(2);

	return (
		<Spin spinning={loading}>
			<Row
				gutter={[16, 16]}
				align="top"
				justify="space-between"
				className="height-auto overflow-auto term"
			>
				<Col className="left">
					<AccountSelect />
				</Col>

				<Col>
					<WalletSelect />
				</Col>

				<Col>
					<FuelSelect onChange={setSelectedFuels} useFuelKey={false} />
				</Col>

				<Col>
					<Space size={10} align="end">
						<ReloadBtn btnText="Оновити" />
						<DownloadBtn
							onClickAction={() => downloadReport('ОтчетПоОстаткам')}
							btnText="Звіт по залишкам"
						/>
						<DownloadMoneyCirculation downloadReport={(reportType, period) => downloadReport(reportType, false, period)}
																			reportType={'ОтчетПоОстаткамИОборотамЕлектронныхТалонов'} />
						<Button onClick={() => setShowRecountBalanceModal(true)}>Перерахувати партії</Button>
					</Space>
				</Col>

				<Table
					columns={isMobile ? columnsTermMobile : columnsTerm}
					locale={{
						triggerDesc: 'Сортувати за спаданням',
						triggerAsc: 'Сортувати за зростанням',
						cancelSort: 'Скасувати сортування',
					}}
					dataSource={selectedFuels.length ? partsByFuels : allParts}
					size="small"
					pagination={{
						showSizeChanger: true,
						pageSizeOptions: ['10', '25', '50', '100'],
						defaultPageSize: 10,
					}}
					className="table-full-width term-table"
					scroll={{ x: 'max-content' }}
					onRow={(part) => {
						return {
							onClick: () => showBalanceModal(part),
						};
					}}
					expandable={
						isMobile
							? {
								expandedRowRender: (v) =>
									columnsTermExpanded.map((item, index) => (
										<div className="expandable-wrap" key={index}>
											<div>{item.title}</div>
											<div className="green">
												{item.render(v[item.key as keyof ITermPart], v)}
											</div>
										</div>
									)),
								columnWidth: 33,
							}
							: undefined
					}
				/>
			</Row>

			{/******************** BALANCE MODAL ********************/}
			<Modal
				className="balance-modal"
				title={'Залишки пального'}
				visible={showModal.showBalance}
				onCancel={() => setShowModal({ ...showModal, showBalance: false })}
				footer={null}
				width="90%"
			>
				<div>
					<div className="details">
            <span className="details-item">
              <span>Дата поповнення: </span>
              <span>{moment(chosenPart?.date).utc().format(FORMAT_DATE)}</span>
            </span>
						<span className="details-item">
              <span>Партія: </span>
              <span>{chosenPart?.part}</span>
            </span>
						<span className="details-item">
              <span>Номенклатура: </span>
              <span className="green">
                {FUELS.find((item) => item.value === chosenPart?.fuel)?.name}
              </span>
            </span>
						<span className="details-item">
              <span>Кількість придбаного пального: </span>
              <span className="green">{chosenPart?.count} л.</span>
            </span>
						<span className="details-item">
              <span>Залишок: </span>
              <span className="green">{chosenPart?.balance} л.</span>
            </span>

						<span
							className="refill cursor"
							style={{ marginBottom: 10 }}
							onClick={() => downloadReport('ОтчетПоОстаткам', true)}
						>
              <Download /> Завантажити звіт
            </span>
						<Table
							columns={balanceColumns}
							dataSource={balanceByPart}
							size="small"
							pagination={{ pageSize: 5 }}
							className="table-full-width"
							scroll={{ x: 'max-content' }}
						/>
					</div>
				</div>
			</Modal>

			{/******************** CONTINUATION MODAL ********************/}
			<ContinuationModal
				visible={showContinuationModal}
				document={document}
				cancelContiuation={cancelContiuation}
			/>

			{/******************** RECOUNT BALANCE MODAL ********************/}
			{showRecountBalanceModal && <RecountBalanceModal
				walletId={wallet}
				isModalOpen={showRecountBalanceModal}
				toggleModal={() => setShowRecountBalanceModal(false)} />
			}
		</Spin>
	);
};

export default Term;
