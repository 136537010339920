import {
	IContractItem,
	INomenclatureItem,
	INominalItem,
	INominalRow,
	IPartyItem,
	IPlasticCardItem,
	IPlasticCardTransactionItem,
	IRecountDataItem,
	IRecountPriceItem,
} from './types';

/*
    Map Cyrillic object keys to Latin correspondings.
 */

const partyAdapter = (parties: any[]): IPartyItem[] =>
	parties.map(({ Партия, Дата, ПартияНаименование, Количество, МинимальныйСрокДействия }) => ({
		id: Партия,
		key: Партия,
		date: Дата,
		name: ПартияНаименование,
		quantity: Количество,
		minValidityPeriod: МинимальныйСрокДействия,
	}));

const contractAdapter = (contracts: any[]): IContractItem[] => contracts.map(({
	Договор,
	ДоговорНаименование,
	Количество,
	СтрокиПартий,
}) => ({
	id: Договор,
	key: Договор,
	name: ДоговорНаименование,
	quantity: Количество,
	parties: partyAdapter(СтрокиПартий),
}));

export const nomenclatureAdapter = (nomenclatures: any[]): INomenclatureItem[] => nomenclatures.map(({
	Номенклатура,
	НоменклатураНаименование,
	СокращенноеОбозначение,
	Количество,
	СтрокиДоговоров,
 }) => ({
	id: Номенклатура,
	key: Номенклатура,
	name: НоменклатураНаименование,
	nameShort: СокращенноеОбозначение,
	quantity: Количество,
	contracts: contractAdapter(СтрокиДоговоров),
}));

const plasticCardAdapter = (cards: any[]): IPlasticCardItem[] => cards.map(({
	Номенклатура,
	НоменклатураНаименование,
	СокращенноеОбозначение,
	Карта,
	КартаПолноеНаименование,
	Состояние,
	ДатаОкончанияДействия,
	Номинал,
	КоличествоЛитров,
}) => ({
	key: КартаПолноеНаименование,
	nomenclature: Номенклатура,
	nomenclatureName: НоменклатураНаименование,
	nomenclatureNameShort: СокращенноеОбозначение,
	cardName: Карта,
	card: КартаПолноеНаименование,
	status: Состояние,
	expiryDate: ДатаОкончанияДействия,
	nominal: Номинал,
	quantity: КоличествоЛитров,
}));

export const nominalAdapter = (nominals: any[]): INominalItem[] => nominals.map(({
	Номенклатура,
	НоменклатураНаименование,
	СокращенноеОбозначение,
	Номинал,
	КоличествоТалонов,
	КоличествоЛитров,
	СтрокиТалонов,
	ДатаОкончанияДействия,
}) => ({
	key: Номинал,
	nomenclature: Номенклатура,
	nomenclatureName: НоменклатураНаименование,
	nomenclatureNameShort: СокращенноеОбозначение,
	nominal: Номинал,
	plasticCardsQuantity: КоличествоТалонов,
	litersQuantity: КоличествоЛитров,
	plasticCards: plasticCardAdapter(СтрокиТалонов),
	expiryDate: ДатаОкончанияДействия,
}));

export const nominalRowAdapter = (nominal: any): INominalRow => {
	const {
		Партия,
		ПартияНаименование,
		Номенклатура,
		НоменклатураНаименование,
		СокращенноеОбозначение,
		СтрокиНоминалов,
	} = nominal;

	return {
		id: Партия,
		partyName: ПартияНаименование,
		nomenclature: Номенклатура,
		nomenclatureName: НоменклатураНаименование,
		nomenclatureNameShort: СокращенноеОбозначение,
		nominals: nominalAdapter(СтрокиНоминалов),
	};
};

export const plasticCardTransactionsAdapter = (transactions: any[]): IPlasticCardTransactionItem[] => transactions.map(({
	Адреса,
	Дата,
	ДоговорКонтрагентаНаименование,
	ДоговорКонтрагентаУИД,
	НоменклатураНаименование,
	НоменклатураУИД,
	Партия,
	ПартияУИД,
	СокращенноеОбозначение,
	Состояние,
	Количество,
	Талон
}, index) => ({
	address: Адреса,
	date: Дата,
	contractName: ДоговорКонтрагентаНаименование,
	contractId: ДоговорКонтрагентаУИД,
	partyName: Партия,
	partyId: ПартияУИД,
	status: Состояние,
	talon: Талон,
	nomenclature: НоменклатураУИД,
	nomenclatureName: НоменклатураНаименование,
	nomenclatureNameShort: СокращенноеОбозначение,
	amount: Количество,
	key: `${index}`
}));

export const recountDataAdapter = (recountData: any[]): IRecountDataItem[] => recountData.map(({
	НоменклатураНаименование,
	НоменклатураУИД,
	Остаток,
	Партия,
	ПартияУИД,
	СокращенноеОбозначение,
	Цена,
	ЦенаТекущая
}, index) => ({
	nomenclature: НоменклатураУИД,
	nomenclatureName: НоменклатураНаименование,
	nomenclatureNameShort: СокращенноеОбозначение,
	leftOver: Остаток,
	partyId: ПартияУИД,
	partyName: Партия,
	price: Цена,
	currentPrice: ЦенаТекущая,
	key: `${index}`
}));

export const recountDataPricesAdapter = (recountDataPrices: any[]): IRecountPriceItem[] => recountDataPrices.map(({
Наименование,
НоменклатураУИД,
СокращенноеОбозначение,
Цена
}) => ({
	nomenclature: НоменклатураУИД,
	nomenclatureName: Наименование,
	nomenclatureNameShort: СокращенноеОбозначение,
	price: Цена,
}));

