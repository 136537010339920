import React, { useEffect, useState } from 'react';

import { Button, Row, Select, Space, Spin, Table } from 'antd';
import AccountSelect from '../../components/selects/AccountSelect';
import { StockOutlined } from '@ant-design/icons';
import PlasticCardsBalanceModal from './components/PlasticCardsBalanceModal';
import { Controller, useForm } from 'react-hook-form';
import locale from 'antd/lib/date-picker/locale/uk_UA';
import { IAvailableFilters, IPlasticCardsTransactionsFormFields, IPlasticCardTransactionItem } from './types';
import moment from 'moment';
import { getPlasticCardTransactions } from '../../services/apiService';
import { plasticCardTransactionsAdapter } from './adapters';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useWindowSize } from '../../hooks';
import { getPlasticCardTransactionsColumns } from './components/tableColumns';
import { LabeledValue } from 'antd/es/select';
import Text from 'antd/es/typography/Text';
import RangePickerWithPeriodLimit from '../../components/RangePickerWithPeriodLimit/RangePickerWithPeriodLimit';

const PlasticCards = () => {
	const { account } = useSelector((state: RootState) => state.account);
	const { width } = useWindowSize();

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [showBalanceModal, setShowBalanceModal] = useState<boolean>(false);
	const [filters, setFilters] = useState<IAvailableFilters>();
	const [tableData, setTableData] = useState<IPlasticCardTransactionItem[]>([]);
	const [filteredTableData, setFilteredTableData] = useState<IPlasticCardTransactionItem[]>([]);
	const [expandedRowKeys, setExpandedRowKeys] = useState<React.Key[]>([]);

	const isMobile: boolean = width ? width < 768 : false;
	const initialTimeRange: [moment.Moment, moment.Moment] = [moment().subtract(3, 'month').startOf('day'), moment().startOf('day')];

	const tableColumns = getPlasticCardTransactionsColumns();
	const {
		control,
		getValues,
		watch,
		reset,
		formState: { isDirty, dirtyFields },
	} = useForm<IPlasticCardsTransactionsFormFields>({
		defaultValues: { timeRange: initialTimeRange },
	});

	const watchedFormFields = watch();
	const userInfo = localStorage.getItem('user');

	// Initial data fetch.
	useEffect(() => fetchTransactions(), [account]);
	// Update filters every time our data changed.
	useEffect(() => setFilters(updateFilters(filteredTableData)), [filteredTableData]);
	// Filter tableData every time filters changed.
	useEffect(() => {
		if (isLoading || !tableData || !isDirty) return;
		onFilter();
	}, [watchedFormFields]);

	const fetchTransactions = () => {
		if (!account) return;

		setIsLoading(true);

		const formatTimeRange = (range: moment.Moment) =>
			range ? range.format('YYYY-MM-DDTHH:mm:ss') + 'Z' : undefined;

		const timeRange = getValues('timeRange');

		getPlasticCardTransactions(account, [formatTimeRange(timeRange[0].startOf('day')), formatTimeRange(timeRange[1].endOf('day'))])
			.then(({ Result }) => {
				if (!Result) return;
				const transactions = plasticCardTransactionsAdapter(Result.Транзакции);
				const filters = filterTransactions(transactions);

				setTableData(transactions);
				setFilteredTableData(filters);
			})
			.catch((err) => console.log(err))
			.finally(() => setIsLoading(false));
	};

	/**
	 * Extracts unique filter options from the given list of transactions.
	 *
	 * @param transactions - List of all the transactions
	 * @returns Filters object based on transactions
	 * */
	const updateFilters = (transactions: IPlasticCardTransactionItem[]): IAvailableFilters => {
		const filters: IAvailableFilters = {
			address: [],
			contractName: [],
			nomenclatureNameShort: [],
			partyName: [],
			status: [],
		};

		const addUniqueLabeledValue = (array: LabeledValue[], value: string) => {
			if (value && !array.some(item => item.label === value)) {
				array.push({ label: value, value });
			}
		};

		transactions.forEach(transaction => {
			addUniqueLabeledValue(filters.address, transaction.address);
			addUniqueLabeledValue(filters.contractName, transaction.contractName);
			addUniqueLabeledValue(filters.nomenclatureNameShort, transaction.nomenclatureNameShort);
			addUniqueLabeledValue(filters.partyName, transaction.partyName);
			addUniqueLabeledValue(filters.status, transaction.status);
		});

		// Sort address alphabetically.
		filters.address.sort((a, b) => (a.label as string).localeCompare(b.label as string));

		// Sort partyName by date (newest first).
		filters.partyName.sort((a, b) => {
			const dateA = extractDateFromPartyName(a.value as string);
			const dateB = extractDateFromPartyName(b.label as string);

			return dateB.getTime() - dateA.getTime();
		});

		return filters;
	};

	/**
	 * Extracts a Date from a partyName string using the part after the `/`.
	 *
	 * @param partyName - The partyName string
	 * @returns Date object parsed from the string
	 */
	const extractDateFromPartyName = (partyName: string): Date => {
		const parts = partyName.split('/');

		if (parts.length > 1) {
			const datePart = parts[1].trim(); // Get the part after the `/`
			const [day, month, year] = datePart.split('.').map(Number);
			return new Date(year, month - 1, day); // Month is zero-based in JavaScript Date
		}

		return new Date(0);
	};

	/**
	 * Filter the array of transactions based on selected user filters.
	 *
	 * @param transactions - List of all the transactions (UNFILTERED)
	 * @returns transactions - List of filtered transactions
	 */
	const filterTransactions = (transactions: IPlasticCardTransactionItem[]): IPlasticCardTransactionItem[] => {
		const { address, contractName, nomenclatureNameShort, partyName } = getValues();
		const availableFilters = {
			address,
			contractName,
			nomenclatureNameShort,
			partyName,
		};

		return transactions.filter((transaction) => Object.entries(availableFilters).every(([key, filterValue]) => {
			if (!filterValue || filterValue.length === 0) return true; // Skip empty filters

			const itemValue = transaction[key as keyof IPlasticCardTransactionItem];
			// Return different values based on filter type (Array or String),
			return Array.isArray(filterValue) ? filterValue.includes(itemValue as string) : itemValue === filterValue;
		}));
	};

	/**
	 * If we've updated date range - Fetch new data.
	 * If we've updated any other filter - Just statically filter existing data.
	 */
	const onFilter = () => {
		dirtyFields.timeRange
			? fetchTransactions()
			: setFilteredTableData(filterTransactions(tableData));

		reset(getValues());
	};

	/**
	 * Update row keys on click.
	 *
	 * @param expanded - boolean - if row expanded
	 * @param record - Transaction obj
	 */
	const onTableRowClick = (expanded: boolean, record: IPlasticCardTransactionItem) => {
		const newExpandedRowKeys = expanded
			? [...expandedRowKeys, record.key]
			: expandedRowKeys.filter(key => key !== record.key);

		setExpandedRowKeys(newExpandedRowKeys);
	};

	return (
		<Spin spinning={isLoading}>
			<Row align="bottom"
					 justify="space-between"
					 wrap
					 style={{ gap: 16, margin: '16px 8px' }}
					 className="height-auto overflow-auto">
				<Space direction="vertical" size="middle">
					<Space align="end" size="middle">
						<AccountSelect />

						{/* DATE. */}
						<Controller
							name="timeRange"
							control={control}
							render={({ field: { value, onChange } }) => (
								<Space direction="vertical">
									<label>Дата</label>
									<RangePickerWithPeriodLimit
										initialTimeRange={initialTimeRange}
										monthToSkip={3}
										locale={locale}
										onChange={(dates) => onChange(dates)}
										inputReadOnly={true}
										value={value}
										renderExtraFooter={() =>
											<Text style={{ fontSize: 14 }}>
												Оберіть початкову та кінцеву дату.
												<br />
												Максимальний період — 3 міс.
											</Text>
										}
										getPopupContainer={(node: any) => node.parentNode as HTMLElement} />
								</Space>
							)}
						/>
					</Space>

					<Space align="end" size="middle" wrap>
						{/* ADDRESS. */}
						<Controller
							name="address"
							control={control}
							render={({ field: { value, onChange } }) => (
								<Space direction="vertical">
									<label>Адреса</label>
									<Select
										style={{ width: isMobile ? 200 : 600 }}
										placeholder="Адреса"
										showSearch
										allowClear
										value={value}
										options={filters?.address || []}
										onChange={onChange}
									/>
								</Space>
							)}
						/>

						{/* NOMENCLATURE. */}
						<Controller
							name="nomenclatureNameShort"
							control={control}
							render={({ field: { value, onChange } }) => (
								<Space direction="vertical">
									<label>Вид пального</label>
									<Select
										style={{ width: 150 }}
										className="select-width"
										placeholder="Вид пального"
										allowClear
										value={value}
										options={filters?.nomenclatureNameShort || []}
										onChange={onChange}
									/>
								</Space>
							)}
						/>

						{/* CONTRACT. */}
						<Controller
							name="contractName"
							control={control}
							render={({ field: { value, onChange } }) => (
								<Space direction="vertical">
									<label>Договір</label>
									<Select
										className="select-width"
										placeholder="Договір"
										showSearch
										style={{ width: 240 }}
										allowClear
										value={value}
										options={filters?.contractName || []}
										onChange={onChange}
									/>
								</Space>
							)}
						/>

						{/* PARTY. */}
						<Controller
							name="partyName"
							control={control}
							render={({ field: { value, onChange } }) => (
								<Space direction="vertical">
									<label>Партія</label>
									<Select
										className="select-width"
										placeholder="Партія"
										allowClear
										style={{ width: 240 }}
										value={value}
										options={filters?.partyName || []}
										onChange={onChange}
									/>
								</Space>
							)}
						/>

						{userInfo && JSON.parse(userInfo).ОстаткиПластиковыхТалонов ? (
							<Button style={{ marginLeft: 'auto' }}
											type="primary"
											onClick={() => setShowBalanceModal(true)}>
								<StockOutlined /> Баланси
							</Button>
						) : null}
					</Space>
				</Space>
			</Row>

			<Table tableLayout="fixed"
						 dataSource={filteredTableData}
						 columns={isMobile ? tableColumns.slice(0, 2) : tableColumns}
						 expandable={
							 isMobile
								 ? {
									 expandedRowKeys: expandedRowKeys,
									 onExpand: onTableRowClick,
									 expandedRowRender: (v) =>
										 tableColumns.slice(2).map((item, index) => (
											 <div className="expandable-wrap" style={{ margin: 0 }} key={index}>
												 <div>{item.title}</div>
												 <div className="green">
													 {item.render ? item.render(v[item.key as keyof IPlasticCardTransactionItem], v, index) : item.key}
												 </div>
											 </div>
										 )),
								 }
								 : undefined
						 } />

			{showBalanceModal &&
				<PlasticCardsBalanceModal
					isModalOpen={true}
					toggleModal={() => setShowBalanceModal(false)} />
			}
		</Spin>

	);
};

export default PlasticCards;