import React, { FC, useContext, useEffect, useState } from 'react';
import { ICommonModalComponentProps } from '../../../interfaces';
import { Modal, Spin, Table } from 'antd';
import {
	getContractTableColumns,
	getNomenclatureTableColumns,
	getNominalsColumns,
	getPartyTableColumns, getPlasticCardsColumns,
} from './tableColumns';
import { IContractItem, INomenclatureItem, INominalItem, INominalRow } from '../types';
import { getClientPlasticCardsBalance, getStatusPlasticCards } from '../../../services/apiService';
import { nomenclatureAdapter, nominalRowAdapter } from '../adapters';
import { AppContext, AppContextInterface } from '../../../AppProvider';
import { useWindowSize } from '../../../hooks';

const PlasticCardsBalanceModal: FC<ICommonModalComponentProps> = ({ isModalOpen, toggleModal }) => {
	const { wallet } = useContext(AppContext) as AppContextInterface;
	const { width } = useWindowSize();

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [allNomenclatures, setAllNomenclatures] = useState<INomenclatureItem[]>();
	const [mainTableExpandedRowKeys, setMainTableExpandedRowKeys] = useState<React.Key[]>([]);
	const [partyTableExpandedRowKeys, setPartyTableExpandedRowKeys] = useState<React.Key[]>([]);
	const [isPartyModalOpen, setPartyModalOpen] = useState<boolean>(false);
	const [partyTableData, setPartyTableData] = useState<INominalRow>();

	const isMobile: boolean = width ? width < 768 : false;

	// Fetch data.
	useEffect(() => {
		if (!wallet) return;

		getClientPlasticCardsBalance(wallet)
			.then(({ Result }) => {
				if (!Result) return;

				setAllNomenclatures(nomenclatureAdapter(Result));
			})
			.catch((err) => console.log(err))
			.finally(() => setIsLoading(false));
	}, [wallet]);

	/**
	 * Handle Main table expandable row click.
	 *
	 * @param expanded - Is row expanded now?
	 * @param record - ANTD Table row data
	 */
	const onMainTableRowExpand = (expanded: boolean, record: INomenclatureItem) =>
		setMainTableExpandedRowKeys(expanded
			? [...mainTableExpandedRowKeys, record.key]
			: mainTableExpandedRowKeys.filter(key => key !== record.key));

	/**
	 * Handle Party table expandable row click.
	 *
	 * @param expanded - Is row expanded now?
	 * @param record - ANTD Table row data
	 */
	const onPartyTableRowExpand = (expanded: boolean, record: INominalItem) =>
		setPartyTableExpandedRowKeys(expanded
			? [...partyTableExpandedRowKeys, record.key]
			: partyTableExpandedRowKeys.filter(key => key !== record.key));

	/**
	 * Fetch and update Party Modal data on party row click.
	 *
	 * @param partyId - Row Party ID
	 * @param nomenclatureId -  Row Nomenclature ID
	 */
	const onPartyRowClick = (partyId: string, nomenclatureId: string) => {
		getStatusPlasticCards(partyId, nomenclatureId)
			.then(({ Result }) => {
				if (!Result) return;

				const data = nominalRowAdapter(Result);
				setPartyTableData(data);
			})
			.catch((err) => console.log(err));
	};

	/**
	 * Open / Close party modal on Party row click.
	 */
	const togglePartyModalVisibility = () => {
		setPartyModalOpen(!isPartyModalOpen);
		isPartyModalOpen && setPartyTableData(undefined);
	};

	/**
	 * Render custom row for card expanded content (LVL 2).
	 *
	 * @param row - ANTD row data
	 */
	const plasticCardRowRender = (row: INominalItem) => (
		<Table
			className="plastic-cards-table"
			tableLayout="fixed"
			showHeader={isMobile}
			dataSource={row.plasticCards || []}
			columns={getPlasticCardsColumns(isMobile ? [1, 3] : false)}
			pagination={false}
		/>
	);

	/**
	 * Custom row for parties (LVL 3).
	 * *
	 * @param row - ANTD row data
	 * @param nomenclatureName - Nomenclature name
	 * @param nomenclatureId - Nomenclature id
	 */
	const partyRowRender = (row: IContractItem, nomenclatureName: string, nomenclatureId: string) => (
		<Table
			tableLayout="fixed"
			className={'expanded-lvl-3'}
			showHeader={isMobile}
			dataSource={row.parties || []}
			onRow={(record) =>
				({
					onClick: event => {
						setPartyModalOpen(true);
						onPartyRowClick(record.id, nomenclatureId);
					},
				})
			}
			columns={getPartyTableColumns(isMobile ? [2, 3] : false, nomenclatureName, row.name)}
			pagination={false}
		/>
	);

	/**
	 * Custom row for contracts (LVL 2).
	 *
	 * @param row - Row data
	 * @param nomenclatureName - Nomenclature name
	 * @param nomenclatureId - Nomenclature ID
	 */
	const contractRowRender = (row: INomenclatureItem, nomenclatureName: string, nomenclatureId: string) => (
		<Table
			tableLayout="fixed"
			className={'expanded-lvl-2'}
			showHeader={isMobile}
			dataSource={row.contracts || []}
			columns={getContractTableColumns(isMobile ? [1] : false, row.name)}
			pagination={false}
			expandable={{
				expandedRowRender: (row) => partyRowRender(row, nomenclatureName, nomenclatureId),
				expandRowByClick: true,
			}}
		/>
	);

	return (
		<Modal className="wrapper"
					 width={'100%'}
					 visible={isModalOpen}
					 title="Історія транзакцій"
					 onCancel={toggleModal}
					 footer={false}>
			<Spin spinning={isLoading}>
				<Table
					className={'parties-table expanded-lvl-1'}
					tableLayout="fixed"
					dataSource={allNomenclatures}
					columns={getNomenclatureTableColumns(isMobile ? [0, 3] : false)}
					expandable={{
						expandedRowRender: (row) => contractRowRender(row, row.name, row.id),
						expandRowByClick: true,
						expandedRowKeys: mainTableExpandedRowKeys,
						onExpand: onMainTableRowExpand,
					}}
				/>
			</Spin>
			<Modal
				title={partyTableData ? `Партія: ${partyTableData?.partyName} - Вид пального: ${partyTableData?.nomenclatureName}` : 'Завантаження даних...'}
				width={'100%'}
				visible={isPartyModalOpen}
				footer={false}
				onCancel={togglePartyModalVisibility}>
				<Spin spinning={!partyTableData}>
					<Table
						tableLayout="fixed"
						dataSource={partyTableData?.nominals}
						columns={getNominalsColumns(isMobile ? [0, 5] : false)}
						expandable={{
							expandedRowRender: (row: any) => plasticCardRowRender(row),
							expandRowByClick: true,
							expandedRowKeys: partyTableExpandedRowKeys,
							onExpand: onPartyTableRowExpand,
						}}
					/>
				</Spin>
			</Modal>
		</Modal>
	);
};

export default PlasticCardsBalanceModal;