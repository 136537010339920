import DownloadBtn from '../DownloadBtn';
import React, { FC, useState } from 'react';
import { Modal } from 'antd';
import locale from 'antd/lib/calendar/locale/uk_UA';
import moment from 'moment/moment';
import { IDownloadMoneyCirculationProps } from './types';
import { Moment } from 'moment';
import RangePickerWithPeriodLimit from '../RangePickerWithPeriodLimit/RangePickerWithPeriodLimit';
import Text from 'antd/es/typography/Text';

// 3 month from now.
const initialTimeRange: [Moment, Moment] = [moment().subtract(3, 'month'), moment()];

const DownloadMoneyCirculation: FC<IDownloadMoneyCirculationProps> = ({ reportType, downloadReport }) => {
	const [isModalOpen, setIsModalOpen] = useState<boolean>();
	const [period, setPeriod] = useState<
		[Moment, Moment]
	>(initialTimeRange);

	const handleOk = () => {
		downloadReport(reportType, period.map(item => item.format('YYYY-MM-DDTHH:mm:ss') + 'Z') as [string, string]);
		setIsModalOpen(false);
	};

	return (
		<>
			<DownloadBtn
				onClickAction={() => setIsModalOpen(true)}
				btnText="Звіт по оборотам"
			/>
			<Modal title="Оберіть період оборотів" visible={isModalOpen}
						 onCancel={() => setIsModalOpen(false)}
						 onOk={handleOk}
						 okText="Завантажити">
				<div className="ant-col">
					<label>Період</label>
				</div>
				<RangePickerWithPeriodLimit
					monthToSkip={12}
					initialTimeRange={initialTimeRange}
					locale={locale}
					onChange={(dates) => {
						if (dates && dates[0] && dates[1]) {
							setPeriod([dates[0], dates[1]]);
						} else {
							setPeriod(initialTimeRange);
						}
					}}
					inputReadOnly={true}
					value={period}
					getPopupContainer={(node: any) => node.parentNode as HTMLElement}
					renderExtraFooter={() =>
						<Text style={{ fontSize: 14 }}>
							Оберіть початкову та кінцеву дату.
							<br />
							Максимальний період — 12 міс.
						</Text>
					}
				/>
			</Modal>
		</>
	);
};

export default DownloadMoneyCirculation;