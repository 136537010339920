import fetcher from '../api';
import { Modal } from 'antd';
import Cookies from 'js-cookie';
import { history } from '../history';
import { IBalance, IOrder } from '../interfaces';
import { EMPTY_GUID, UNDISTRIBUTED_PHONE } from '../constants';
import moment from 'moment';

const clearLocalStorage = () => {
	localStorage.removeItem('ik:phone');
	localStorage.removeItem('account');
	localStorage.removeItem('wallet');
	localStorage.removeItem('guid');
};

export const Logout = () => {
	Cookies.remove('token');
	clearLocalStorage();
	history.push('/');
	window.location.reload();
};

const showError = (error: any) => {
	let errorText = '';
	switch (error.status) {
		case 401:
		case 408:
			Logout();
			break;
		default:
			errorText = 'Невідома помилка';
	}
	if (typeof error.json === 'function') {
		error.json().then((res: any) => {
			Modal.error({
				title: 'Помилка',
				content: res.message || errorText,
				centered: true,
			});
		});
	} else {
		Modal.error({
			title: 'Помилка',
			content: errorText,
			centered: true,
		});
	}

	return false;
};

const checkStatus = ({ Error, Status }: { Error: string; Status: number }) => {
	if (Status === 5) {
		Logout();
		return;
	}
	if (Status > 0) {
		Modal.error({
			title: 'Помилка',
			content: Error || 'Невідома помилка',
			centered: true,
		});
	}
};

export const sendPhoneNumber = async (phone: string | null) => {
	const res = await fetcher.post(
		{
			url: `PreAuthorization/${phone}`,
			data: {},
		},
		showError,
	);
	checkStatus(res);
	return (await res) || [];
};

export const sendAuthorization = async (code: string) => {
	const phone = localStorage.getItem('ik:phone');
	const res = await fetcher.post(
		{
			url: `Authorization/${phone}`,
			data: {
				НомерТелефона: phone,
				ПарольОТП: code,
				ИдМобильногоПриложения: '',
				ТипОС: 2,
			},
		},
		showError,
	);
	checkStatus(res);
	return (await res) || [];
};

export const getOrganizations = async () => {
	const phone = localStorage.getItem('ik:phone');
	const token: string | undefined = Cookies.get('token');

	const res = await fetcher.post(
		{
			url: `Customers/${phone}/${token}`,
			data: {
				НомерТелефона: phone,
			},
		},
		showError,
	);
	checkStatus(res);
	return (await res) || [];
};

export const getWallets = async (account: string | undefined) => {
	const phone = localStorage.getItem('ik:phone');
	const token: string | undefined = Cookies.get('token');

	const res = await fetcher.post(
		{
			url: `Wallets/${phone}/${token}`,
			data: {
				НомерТелефона: phone,
				Контрагент: account, //localStorage.getItem("account")
			},
		},
		showError,
	);
	checkStatus(res);
	return (await res) || [];
};

export const getWalletAccounts = async (wallet: string | undefined) => {
	const phone = localStorage.getItem('ik:phone');
	const token: string | undefined = Cookies.get('token');

	const res = await fetcher.post(
		{
			url: `WalletAccounts/${phone}/${token}`,
			data: {
				НомерТелефона: phone,
				Счет: wallet,
			},
		},
		showError,
	);
	checkStatus(res);
	return (await res) || [];
};

export const postChangeUser = async (
	wallet: string | undefined,
	{ alias, status, userPhone }: Partial<IBalance>,
	changeAccountStatus = false,
) => {
	const phone = localStorage.getItem('ik:phone');
	const token: string | undefined = Cookies.get('token');
	const changeStatus = !status ? 1 : 0;

	const res = await fetcher.post(
		{
			url: `ChangeAccount/${phone}/${token}`,
			data: {
				НомерТелефона: phone,
				ПодсоединяемыйНомерТелефона: userPhone,
				Счет: wallet,
				Псевдоним: alias,
				Включен: changeAccountStatus ? changeStatus : Number(status),
			},
		},
		showError,
	);
	checkStatus(res);
	return (await res) || [];
};

export const connectAccount = async (
	wallet: string | undefined,
	{ alias, telephone }: { alias: string; telephone: string },
) => {
	const phone = localStorage.getItem('ik:phone');
	const token: string | undefined = Cookies.get('token');

	const res = await fetcher.post(
		{
			url: `ConnectAccount/${phone}/${token}`,
			data: {
				НомерТелефона: phone,
				ПодсоединяемыйНомерТелефона: telephone.replace(/\+|\(|\)| /gi, ''),
				Счет: wallet,
				Псевдоним: alias,
			},
		},
		showError,
	);
	checkStatus(res);
	return (await res) || [];
};

export const getTransactions = async (
	userPhone: string,
	wallet: string | undefined,
	startDate: string | undefined,
	endDate: string | undefined,
) => {
	const phone = localStorage.getItem('ik:phone');
	const token: string | undefined = Cookies.get('token');

	const res = await fetcher.post(
		{
			url: `Transactions/${phone}/${token}`,
			data: {
				НомерТелефона: phone,
				Счет: wallet,
				Дата1: startDate,
				Дата2: endDate,
				АккаунтСчета:
					userPhone !== EMPTY_GUID ? userPhone : UNDISTRIBUTED_PHONE,
			},
		},
		showError,
	);
	checkStatus(res);
	return (await res) || [];
};

export const moveBalance = async (order: IOrder) => {
	const phone = localStorage.getItem('ik:phone');
	const token: string | undefined = Cookies.get('token');
	const otherUserPhone = order.otherUserPhone?.replace(/\+|\(|\)| /gi, '');

	const res = await fetcher.post(
		{
			url: `MoveBalance/${phone}/${token}`,
			data: {
				НомерТелефона: phone,
				Заказ: {
					Ид: order.id,
					НомерТелефонаОтправителя:
						order.phone !== EMPTY_GUID ? order.phone : UNDISTRIBUTED_PHONE,
					НомерТелефонаПолучателя:
						order.phoneReciever !== EMPTY_GUID
							? order.phoneReciever || otherUserPhone
							: UNDISTRIBUTED_PHONE,
					Номенклатура: order.fuel,
					Количество: +order.amount,
					Счет: order.account,
					Партия: order.part,
					ПереводФизлицу: !!order.otherUserPhone,
					ПереводМеждуСчетами: !!order.transferWallet,
					СчетПолучатель: order.transferWallet,
				},
			},
		},
		showError,
	);
	console.log(res);
	checkStatus(res);
	return (await res) || [];
};

export const validateTransferPhoneNumber = async (phoneNumber: string) => {
	const phone = localStorage.getItem('ik:phone');
	const token: string | undefined = Cookies.get('token');
	const validPhoneNumber = phoneNumber.replace(/\+|\(|\)| /gi, '');
	const res = await fetcher.post(
		{
			url: `AccountExists/${phone}/${token}`,
			data: {
				НомерТелефонаПолучателя: validPhoneNumber,
			},
		},
		showError,
	);
	return (await res) || [];
};

export const getInvoiceHistory = async (
	wallet: string | undefined,
	startDate: string | undefined,
	endDate: string | undefined,
) => {
	const phone = localStorage.getItem('ik:phone');
	const token: string | undefined = Cookies.get('token');
	const res = await fetcher.post(
		{
			url: `InvoiceHistory/${phone}/${token}`,
			data: {
				Счет: wallet,
				Дата1: startDate,
				Дата2: endDate,
			},
		},
		showError,
	);
	return (await res) || [];
};

export const getInvoicePrice = async (wallet: string | undefined) => {
	const phone = localStorage.getItem('ik:phone');
	const token: string | undefined = Cookies.get('token');
	const res = await fetcher.post(
		{
			url: `WalletPrices/${phone}/${token}`,
			data: {
				Счет: wallet,
			},
		},
		showError,
	);
	return (await res) || [];
};

export const getFuelLimit = async (
	wallet: string | undefined,
	nomenclature: string | undefined,
) => {
	const phone = localStorage.getItem('ik:phone');
	const token: string | undefined = Cookies.get('token');
	const res = await fetcher.post(
		{
			url: `FuelLimit/${phone}/${token}`,
			data: {
				Счет: wallet,
				Номенклатура: nomenclature,
			},
		},
		showError,
	);
	return (await res) || [];
};

export const sendInvoice = async (
	wallet?: string,
	nomenclature?: string,
	count?: number,
	price?: number,
	mail?: string,
) => {
	const phone = localStorage.getItem('ik:phone');
	const token: string | undefined = Cookies.get('token');
	const res = await fetcher.post(
		{
			url: `Invoice/${phone}/${token}`,
			data: {
				Счет: wallet,
				Email: mail,
				Заказ: [
					{
						Номенклатура: nomenclature,
						Количество: count,
						Цена: price,
					},
				],
			},
		},
		showError,
	);

	return (await res) || [];
};

export const getFuelCards = async (wallet?: string) => {
	const phone = localStorage.getItem('ik:phone');
	const token: string | undefined = Cookies.get('token');
	const res = await fetcher.post(
		{
			url: `FuelCardsBalance/${phone}/${token}`,
			data: {
				Счет: wallet,
			},
		},
		showError,
	);

	return (await res) || [];
};

export const createFuelCards = async (
	expireDate: string,
	wallet: string,
	account: string,
	part: string,
	mail: string,
	countByOne: number,
	talonCount: number,
	fuel: string,
) => {
	const phone = localStorage.getItem('ik:phone');
	const token: string | undefined = Cookies.get('token');
	const res = await fetcher.post(
		{
			url: `CreateFuelCards/${phone}/${token}`,
			data: {
				Счет: wallet,
				Аккаунт: account,
				Партия: part,
				ЭлектронныйАдрес: mail,
				СрокДействия: `${expireDate}T00:00:00Z`,
				Заказ: [
					{
						Номенклатура: fuel,
						Количество: countByOne * talonCount,
						Номинал: countByOne,
						КоличествоШт: talonCount,
					},
				],
			},
		},
		showError,
	);

	return (await res) || [];
};

export const refundFuelCard = async (uuid?: string) => {
	const phone = localStorage.getItem('ik:phone');
	const token: string | undefined = Cookies.get('token');
	const res = await fetcher.post(
		{
			url: `UnblockFuelCards/${phone}/${token}`,
			data: {
				УИДГенерации: uuid,
			},
		},
		showError,
	);

	return (await res) || [];
};

export const downloadFuelCard = async (uuid?: string) => {
	const phone = localStorage.getItem('ik:phone');
	const token: string | undefined = Cookies.get('token');
	const res = await fetcher.post(
		{
			url: `DownloadFuelCards/${phone}/${token}`,
			data: {
				УИДГенерации: uuid,
			},
		},
		showError,
	);

	return (await res) || [];
};

export const continuationOfValidityPeriod = async (uuid?: string) => {
	const phone = localStorage.getItem('ik:phone');
	const token: string | undefined = Cookies.get('token');

	const res = await fetcher.post(
		{
			url: `ContinuationOfValidityPeriod/${phone}/${token}`,
			data: {
				УИД: uuid,
			},
		},
		showError,
	);

	return (await res) || {};
};

export const checkPartPrice = async (uuid?: string) => {
	const phone = localStorage.getItem('ik:phone');
	const token: string | undefined = Cookies.get('token');

	const res = await fetcher.post(
		{
			url: `CheckPartPrice/${phone}/${token}`,
			data: {
				УИД: uuid,
			},
		},
		showError,
	);

	return (await res) || {};
};

export const transactionsReport = async (
	userPhone: string,
	wallet: string | undefined,
	startDate: string | undefined,
	endDate: string | undefined,
	byGroup: boolean,
) => {
	const phone = localStorage.getItem('ik:phone');
	const token: string | undefined = Cookies.get('token');

	const res = await fetcher.post(
		{
			url: `Reports/${phone}/${token}`,
			data: {
				Действие: 'ОтчетПоТранзакциям',
				НомерТелефона: phone,
				Счет: wallet,
				Дата1: startDate,
				Дата2: endDate,
				АккаунтСчета:
					userPhone !== EMPTY_GUID ? userPhone : UNDISTRIBUTED_PHONE,
				ОтображатьГрупировки: byGroup,
			},
		},
		showError,
	);
	checkStatus(res);
	return (await res) || {};
};

export const balanceReport = async (
	wallet: string | undefined,
	document: string | undefined,
	part: string | undefined,
	option: string,
	reportType: string,
	period?: [string, string]
) => {
	const phone = localStorage.getItem('ik:phone');
	const token: string | undefined = Cookies.get('token');

	const res = await fetcher.post(
		{
			url: `Reports/${phone}/${token}`,
			data: {
				Действие: reportType,
				НомерТелефона: phone,
				Счет: wallet,
				Вариант: option,
				Партия: document,
				ПартияПредставление: part,
				...(period && { ДатаНачала: period[0], ДатаОкончания: period[1] }),
			},
		},
		showError,
	);
	checkStatus(res);
	return (await res) || {};
};

export const balanceReportFuelCard = async (wallet: string | undefined, reportName: string, period?: [string, string]) => {
	const phone = localStorage.getItem('ik:phone');
	const token: string | undefined = Cookies.get('token');

	const res = await fetcher.post(
		{
			url: `Reports/${phone}/${token}`,
			data: {
				Действие: reportName,
				НомерТелефона: phone,
				Счет: wallet,
				...(period && { ДатаНачала: period[0], ДатаОкончания: period[1] }),
			},
		},
		showError,
	);
	checkStatus(res);
	return (await res) || {};
};

export const getTransactionsFuelCard = async (
	userPhone: string | undefined,
	wallet: string | undefined,
	startDate: string | undefined,
	endDate: string | undefined,
	fuel: string | undefined,
	part: string | undefined,
) => {
	const phone = localStorage.getItem('ik:phone');
	const token: string | undefined = Cookies.get('token');

	const res = await fetcher.post(
		{
			url: `FuelCardsTransactions/${phone}/${token}`,
			data: {
				НомерТелефона: phone,
				Счет: wallet,
				Дата1: startDate,
				Дата2: endDate,
				АккаунтСчета:
					userPhone !== EMPTY_GUID ? userPhone : UNDISTRIBUTED_PHONE,
				Номенклатура: fuel,
				Партия: part,
			},
		},
		showError,
	);
	checkStatus(res);
	return (await res) || [];
};

export const transactionsFuelCardReport = async (
	userPhone: string | undefined,
	wallet: string | undefined,
	startDate: string | undefined,
	endDate: string | undefined,
	fuel: string | undefined,
	part: string | undefined,
) => {
	const phone = localStorage.getItem('ik:phone');
	const token: string | undefined = Cookies.get('token');

	const res = await fetcher.post(
		{
			url: `Reports/${phone}/${token}`,
			data: {
				Действие: 'ОтчетПоТранзакциямОТ',
				НомерТелефона: phone,
				Счет: wallet,
				Дата1: startDate,
				Дата2: endDate,
				АккаунтСчета:
					userPhone !== EMPTY_GUID ? userPhone : UNDISTRIBUTED_PHONE,
				Номенклатура: fuel,
				Партия: part,
			},
		},
		showError,
	);
	checkStatus(res);
	return (await res) || {};
};

export const downloadInvoice = async (uuid?: string) => {
	const phone = localStorage.getItem('ik:phone');
	const token: string | undefined = Cookies.get('token');
	const res = await fetcher.post(
		{
			url: `DownloadInvoice/${phone}/${token}`,
			data: {
				УИДСчета: uuid,
			},
		},
		showError,
	);

	return (await res) || [];
};

export const getWalletAdmins = async (uuid?: string) => {
	const phone = localStorage.getItem('ik:phone');
	const token: string | undefined = Cookies.get('token');
	const res = await fetcher.post(
		{
			url: `ClientInfo/${phone}/${token}`,
			data: {
				УИДСчета: uuid,
				Действие: 'GetWalletAdmins',
			},
		},
		showError,
	);

	return (await res) || [];
};

export const getGetContactsForClient = async (uuid?: string) => {
	const phone = localStorage.getItem('ik:phone');
	const token: string | undefined = Cookies.get('token');
	const res = await fetcher.post(
		{
			url: `ClientInfo/${phone}/${token}`,
			data: {
				УИД: uuid,
				Действие: 'GetContactsForClient',
			},
		},
		showError,
	);

	return (await res) || [];
};

export const getClientsFileList = async () => {
	const phone = localStorage.getItem('ik:phone');
	const token: string | undefined = Cookies.get('token');
	const res = await fetcher.post(
		{
			url: `ClientInfo/${phone}/${token}`,
			data: {
				Действие: 'GetClientsFileList',
			},
		},
		showError,
	);

	return (await res) || [];
};

export const getClientsFile = async (fileIndex?: string) => {
	const phone = localStorage.getItem('ik:phone');
	const token: string | undefined = Cookies.get('token');
	const res = await fetcher.post(
		{
			url: `ClientInfo/${phone}/${token}`,
			data: {
				Действие: 'GetClientInstruction',
				ИмяФайла: fileIndex,
			},
		},
		showError,
	);

	return (await res) || [];
};

/**
 * Get all nomenclatures of plastic card.
 *
 * @param wallet - Target wallet
 */
export const getClientPlasticCardsBalance = async (wallet: string) => {
	const phone = localStorage.getItem('ik:phone');
	const token: string | undefined = Cookies.get('token');
	const res = await fetcher.post(
		{
			url: `PlasticCardsBalance/${phone}/${token}`,
			data: {
				Счет: wallet,
			},
		},
		showError,
	);

	return (await res) || [];
};

/**
 * Get the status of Party and related info.
 *
 * @param partyId - Party ID
 * @param nomenclatureId - Nomenclature ID
 */
export const getStatusPlasticCards = async (partyId: string, nomenclatureId: string) => {
	const phone = localStorage.getItem('ik:phone');
	const token: string | undefined = Cookies.get('token');
	const res = await fetcher.post(
		{
			url: `StatusPlasticCards/${phone}/${token}`,
			data: {
				Партия: partyId,
				Номенклатура: nomenclatureId,
			},
		},
		showError,
	);

	return (await res) || [];
};

/**
 * Get plastic cards transactions history.
 *
 * @param account - User ID
 * @param timePeriods - From/To period range
 */
export const getPlasticCardTransactions = async (account: string, timePeriods: [string | undefined, string | undefined]) => {
	const phone = localStorage.getItem('ik:phone');
	const token: string | undefined = Cookies.get('token');
	const res = await fetcher.post(
		{
			url: `TransactionsPlasticCards/${phone}/${token}`,
			data: {
				Контрагент: account,
				ПериодЗ: timePeriods[0],
				ПериодПо: timePeriods[1],
			},
		},
		showError,
	);

	return (await res) || [];
};

/**
 * Get recount Balance data of the wallet.
 *
 * @param wallet - Wallet ID
 */
export const getRecountBalance = async (wallet: string) => {
	const phone = localStorage.getItem('ik:phone');
	const token: string | undefined = Cookies.get('token');
	const res = await fetcher.post(
		{
			url: `RecountBalance/${phone}/${token}`,
			data: {
				Счет: wallet,
			},
		},
		showError,
	);

	return (await res) || [];
};

/**
 * Post recount/return application of parties leftover to manager.
 *
 * @param selectedParties - Array of parties user wants to recount
 * @param isReturn - if true - we return / if false - we recount
 * @param targetNomenclatureId - Recount Nomenclature id
 * @param targetNomenclatureName - Recount Nomenclature name
 * @param amount - Amount of liters to recount
 */
export const postSelectedRecountBalance = async (
	selectedParties: any[],
	isReturn: boolean,
	targetNomenclatureId: string,
	targetNomenclatureName: string,
	amount: number,
) => {
	const phone = localStorage.getItem('ik:phone');
	const token: string | undefined = Cookies.get('token');
	const res = await fetcher.post(
		{
			url: `SelectedRecountBalance/${phone}/${token}`,
			data: {
				ВыбранныеПартии: selectedParties,
				Возврат: isReturn,
				НоменклатураПерерасчета: targetNomenclatureName,
				НоменклатураПерерасчетаУИД: targetNomenclatureId,
				КоличествоПерарасчета: amount,
			},
		},
		showError,
	);

	return (await res) || [];
};